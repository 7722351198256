import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import store from "../store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    component: () => import("../views/SplashscreenPage.vue"),
  },
  {
    path: "/logout",
    component: () => import("../views/LogoutPage.vue"),
  },
  {
    path: "/auth/:type",
    component: () => import("../views/SplashscreenPage.vue"),
  },
  {
    path: "/login",
    component: () => import("../views/InitialPage.vue"),
  },
  {
    path: "/welcome",
    name: "Welcome",
    component: () => import("../views/Login2Page.vue"),
  },
  {
    path: "/login/:type",
    component: () => import("../views/LoginPage.vue"),
  },
  {
    path: "/profile",
    component: () => import("../views/customer/ProfilePage.vue"),
    meta: {
      requiresAuth: false,
      page: "profile",
      onlyCustomer: true,
    },
  },
  {
    path: "/profile-gardener",
    component: () => import("../views/gardener/ProfileGardenerPage.vue"),
    meta: {
      requiresAuth: false,
      page: "profile",
      onlyGardener: true,
    },
  },

  {
    path: "/start",
    component: () => import("../views/customer/ProfilePage.vue"),
    meta: {
      requiresAuth: false,
      page: "start",
      onlyCustomer: true,
    },
  },
  {
    path: "/start-gardener",
    component: () => import("../views/gardener/ProfileGardenerPage.vue"),
    meta: {
      requiresAuth: false,
      page: "start",
      onlyGardener: true,
    },
  },
  {
    path: "/start/garden",
    component: () => import("../views/customer/GardenPage.vue"),
    meta: {
      requiresAuth: false,
      page: "start",
      onlyCustomer: true,
    },
  },
  {
    path: "/garden",
    component: () => import("../views/customer/GardenPage.vue"),
    meta: {
      requiresAuth: false,
      page: "create",
      onlyCustomer: true,
    },
  },
  {
    path: "/gardens",
    component: () => import("../views/customer/GardensPage.vue"),
    meta: {
      requiresAuth: true,
      onlyCustomer: true,
    },
  },
  {
    path: "/garden/:id",
    component: () => import("../views/customer/GardenPage.vue"),
    meta: {
      requiresAuth: true,
      page: "edit",
      onlyCustomer: true,
    },
  },
  {
    path: "/home",
    component: () => import("../views/customer/HomePage.vue"),
    meta: {
      requiresAuth: true,
      onlyCustomer: true,
    },
  },

  {
    path: "/home-gardener",
    component: () => import("../views/gardener/HomeGardenerPage.vue"),
    meta: {
      requiresAuth: true,
      onlyGardener: true,
    },
  },
  {
    path: "/history-gardener",
    component: () => import("../views/gardener/HistoryOrder.vue"),
    meta: {
      requiresAuth: true,
      onlyGardener: true,
    },
  },
  {
    path: "/orders",
    component: () => import("../views/customer/OrdersPage.vue"),
    meta: {
      requiresAuth: true,
      onlyCustomer: true,
    },
  },
  {
    path: "/order/:id",
    component: () => import("../views/customer/OrdersPage.vue"),
    meta: {
      requiresAuth: true,
      page: "edit",
      onlyCustomer: true,
    },
  },
  {
    path: "/folder/:id",
    component: () => import("../views/FolderPage.vue"),
    meta: {
      requiresAuth: true,
      onlyCustomer: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const customer =
    localStorage.getItem("customer") &&
    JSON.parse(localStorage.getItem("customer") as string);

  const gardener =
    localStorage.getItem("gardener") &&
    JSON.parse(localStorage.getItem("gardener") as string);

  if (customer) {
    store.commit("auth/setUser", customer);
  }

  if (gardener) {
    store.commit("auth/setUser", gardener);
  }

  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);

  firebase.auth().onAuthStateChanged((user) => {
    if (requiresAuth && !user) {
      next("/");
    } else {
      // if (to.meta?.onlyCustomer && !customer?.isCustomer) {
      //   next("/");
      // }
      // if (to.meta?.onlyGardener && !gardener?.isGardener) {
      //   next("/");
      // }

      next();
    }
  });
});

export default router;
