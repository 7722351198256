interface User {
  name: string;
}

interface AuthState {
  user: User | null;
}

const state: AuthState = {
  user: null,
};

const getters = {
  user: (state: AuthState) => state.user,
};

const mutations = {
  setUser: (stete: AuthState, user: User) => {
    stete.user = user;
  },
};

const actions = {
  signIn({ commit }: any, user: User) {
    commit("setUser", user);
  },
  signOut({ commit }: any) {
    commit("setUser", null);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
