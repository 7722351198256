
import {
  IonContent,
  IonMenu,
  IonSplitPane,
  IonMenuToggle,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonListHeader,
} from "@ionic/vue";
import { defineComponent, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import firebase from "firebase/compat/app";
import "firebaseui/dist/firebaseui.css";

export default defineComponent({
  name: "MenuApp",
  components: {
    IonContent,
    IonSplitPane,
    IonMenu,
    IonMenuToggle,
    IonItem,
    IonLabel,
    IonList,
    IonNote,
    IonListHeader,
  },
  props: {
    enableMenu: {
      type: Boolean,
      required: true,
    },
  },

  setup() {
    const selectedIndex = ref(0);

    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const user = computed(() => store.state.auth.user);

    const profilePhoto = computed(() => {
      return require("@/assets/menu/icon_user.svg");
    });

    const isGardener = computed(() => {
      return store.state.auth.user?.isGardener;
    });

    const isCustomer = computed(() => {
      return store.state.auth.user?.isCustomer;
    });

    const goTo = (path: string) => {
      if (path === route.path) {
        return;
      }
      router.push(path);
    };

    const bye = () => {
      console.log("bye");
    };

    const appPages = [
      {
        title: "Home",
        url: "/home",
        icon: require("@/assets/menu/icon_home.svg"),
        iconActive: require("@/assets/menu/icon_home_active.svg"),
      },
      {
        title: "My Gardens",
        url: "/gardens",
        icon: require("@/assets/menu/icon_garden.svg"),
        iconActive: require("@/assets/menu/icon_garden_active.svg"),
      },
      {
        title: "My Orders",
        url: "/home",
        icon: require("@/assets/menu/icon_order.svg"),
        iconActive: require("@/assets/menu/icon_order_active.svg"),
      },
      {
        title: "Profile",
        url: "/profile",
        icon: require("@/assets/menu/icon_profile.svg"),
        iconActive: require("@/assets/menu/icon_profile_active.svg"),
      },
      {
        title: "Logout",
        url: "/Logout",
        icon: require("@/assets/menu/icon_logout.svg"),
        iconActive: require("@/assets/menu/icon_logout.svg"),
      },
    ];

    const appPagesGardener = [
      {
        title: "Home",
        url: "/home-gardener",
        icon: require("@/assets/menu/icon_home.svg"),
        iconActive: require("@/assets/menu/icon_home_active.svg"),
      },

      {
        title: "Profile",
        url: "/profile-gardener",
        icon: require("@/assets/menu/icon_profile.svg"),
        iconActive: require("@/assets/menu/icon_profile_active.svg"),
      },
      {
        title: "History",
        url: "/history-gardener",
        icon: require("@/assets/menu/icon_history.svg"),
        iconActive: require("@/assets/menu/icon_history_active.svg"),
      },
      {
        title: "Logout",
        url: "/Logout",
        icon: require("@/assets/menu/icon_logout.svg"),
        iconActive: require("@/assets/menu/icon_logout.svg"),
      },
    ];

    return {
      selectedIndex,
      isSelected: (url: string) => (url === route.path ? "selected" : ""),
      profilePhoto,
      goTo,
      appPages,
      appPagesGardener,
      user,
      isGardener,
      isCustomer,
    };
  },
});
