
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { defineComponent, ref, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import MenuApp from "@/components/menu.vue";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    MenuApp,
  },
  setup() {
    const selectedIndex = ref(0);

    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const user = computed(() => store.state.auth.user);

    const isGardener = computed(() => {
      return store.state.auth.user?.isGardener;
    });

    const isCustomer = computed(() => {
      return store.state.auth.user?.isCustomer;
    });

    const enableMenu = computed(() => {
      const disableMenuRoutes = [
        "",
        "/login",
        "/register",
        "/",
        "/welcome",
        "/login/customer",
        "/login/gardener",
        "/auth/customer",
        "/auth/gardener",
        "/start",
        "/start-gardener",
        "/logout",
      ];

      if (!isCustomer.value || !isGardener.value) {
        return true;
      }

      return !disableMenuRoutes.includes(route.path);
    });

    const goTo = (path: string) => {
      if (path === route.path) {
        return;
      }
      router.push(path);
    };

    onMounted(() => {
      const customer =
        localStorage.getItem("customer") &&
        JSON.parse(localStorage.getItem("customer") as string);
      const gardener =
        localStorage.getItem("gardener") &&
        JSON.parse(localStorage.getItem("gardener") as string);

      // const isLogout = await localStorage.getItem("isLogout");

      if (customer) {
        store.commit("auth/setUser", customer);
      }
      if (gardener) {
        store.commit("auth/setUser", gardener);
      }
    });

    return {
      selectedIndex,
      isSelected: (url: string) => (url === route.path ? "selected" : ""),
      enableMenu,
      goTo,
      isGardener,
      isCustomer,
      user,
    };
  },
});
